<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-btn
          color="primary"
          fab
          dark
          to="/pages/configurador-frete/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Configurações
            <v-spacer />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="configuracoes"
            :options.sync="options"
            :server-items-length="total"
            class="elevation-1"
            :footer-props="footer_props"
          >
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.freteId"
                >
                  <td>{{ item.estado.nome }}</td>
                  <td>{{ item.pesoMaximo }}g</td>
                  <td>R$ {{ item.preco.toFixed(2) }}</td>
                  <td>
                    {{ item.tipo.nome }}
                  </td>
                  <td>
                    <router-link :to="`/pages/configurador-frete/${item.freteId}`">
                      <v-icon
                        class="mr-2"
                        color="info"
                      >
                        mdi-pencil
                      </v-icon>
                    </router-link>
                    <v-icon
                      color="error"
                      @click="destroy(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          A configuração será deletada. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialog = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

  export default {
    name: 'CategoriaList',
    data: () => ({
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      itemselected: {},
      configuracoes: [],
      headers: [
        { text: 'Estado', sortable: false },
        { text: 'Peso Máximo (em gramas)', sortable: false },
        { text: 'Preço', sortable: false },
        { text: 'Tipo', sortable: false },
        { text: 'Ações', value: 'action', sortable: false },
      ],
      dialog: false,
      total: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        Term: '',
      },
    }),
    watch: {
      options: {
        handler () {
          this.load()
        },
        deep: true,
      },
    },
    methods: {
      load: function () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        const query = JSON.stringify(this.filter)
        this.$http.get('/fretes', { params: { query } })
          .then(resp => {
            this.configuracoes = resp.data.rows
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      edit (item) {
        this.$router.push(`/configurador-frete/${item.freteId}`)
      },
      destroy (item) {
        this.itemselected = item
        this.dialog = true
      },
      confirmDelete () {
        this.$http.delete(`/fretes/${this.itemselected.freteId}`)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.dialog = false
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
    },
  }
</script>

<style>
a {
  text-decoration: none;
}
</style>
